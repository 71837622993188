@import url(https://fonts.googleapis.com/css?family=Poppins);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*// default theme(light)*/
html {
    --primaryBackground: #0E131A;
    --primaryForeGround: #191F28;
    --primaryTextColor: #FFFFFF;
    --primaryButtonColor: #49484D;


    --blueGradientDark: #4179F9;
    --blueGradientLight: #05B0DA;

}

/*// dark theme*/
html[data-theme="light"] {
    --primaryBackground: #1E1E20;
    --primaryForeGround: #fff;
    --primaryTextColor: #FFFFFF;
}

body {
    font-family: "Poppins", "Helvetica Neue", serif !important;
    background: #0E131A;
    background: var(--primaryBackground); /* Old browsers */
}

.ui{
    font-family: "Poppins", "Helvetica Neue", serif !important;

}

.ui.button{

}

.ui.inverted.form.inline.fields {
    text-align: center;
    display: inline-block !important;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center
}

.ui.inverted.top.fixed.menu {
    background: #191F28;
    background: var(--primaryForeGround);
}

.ui.inverted.segment,
.ui.primary.inverted.segment {
    background: #191F28;
    background: var(--primaryForeGround);
    color: rgba(1, 1, 1, 0.9);
    font-family: "Poppins", "Helvetica Neue", serif !important;
}

.ui.fluid.selection.dropdown {
    background: #191F28;
    background: var(--primaryForeGround);
    color: white;
    border-color: #2B3453;
}

.ui.fluid.selection.dropdown.selected > div{
    color: white;
    border-color: #2B3453;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */; /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #4179F9 0%, #05B0DA 100%) !important;; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #4179F9 0%, #05B0DA 100%) !important;; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4179F9', endColorstr='#1E243A', GradientType=0); /* IE6-9 */
}

.ui.inverted.segment.dashboard-section {
    /*max-width: calc(100% - 2em);*/
    margin-bottom: 1.5em;
    margin-top: 0.5em;
    width: 100%;
    max-height: 80vh;
}

.ui.inverted.segment.overflow {
    overflow: auto;
}

.ui.button {
    background: #49484D;
    background: var(--primaryButtonColor);
    color: #FFFFFF;
    color: var(--primaryTextColor);
    font-weight: 300;
}

.ui.primary.button, .ui.pagination.menu .active.item{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */ /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #4179F9 0%, #05B0DA 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #4179F9 0%, #05B0DA 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4179F9', endColorstr='#1E243A', GradientType=0); /* IE6-9 */
    color: white;

    font-weight: 500 !important;
}

.ui.secondary.button {
    background: #3968BA; /* Old browsers */ /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #2b9439 0%, #4dde50 100%); /* Chrome10-25,Safari5.1-6 */
    background: -webkit-linear-gradient(bottom left, #2b9439 0%, #4dde50 170%);
    background: linear-gradient(to top right, #2b9439 0%, #4dde50 170%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#151A22', endColorstr='#191F28', GradientType=0); /* IE6-9 */

    color: white;
}

.ui.secondary.button.livingstone{
    background: #3968BA; /* Old browsers */ /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #92c01f 0%, #4dde50 100%); /* Chrome10-25,Safari5.1-6 */
    background: -webkit-linear-gradient(bottom left, #92c01f 0%, #4dde50 170%);
    background: linear-gradient(to top right, #92c01f 0%, #4dde50 170%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#92c01f', endColorstr='#191F28', GradientType=0); /* IE6-9 */

    color: white;
}

.ui.positive.button{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */ /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #4179F9 0%, #05B0DA 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #4179F9 0%, #05B0DA 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4179F9', endColorstr='#1E243A', GradientType=0); /* IE6-9 */
}

/*.ui.disabled.button{*/
/*!* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 *!*/
/*background: #222544; !* Old browsers *!*/
/*background: -moz-linear-gradient(left, #4179F9 0%, #05B0DA 100%); !* FF3.6-15 *!*/
/*background: -webkit-linear-gradient(left, #4179F9 0%, #05B0DA 100%); !* Chrome10-25,Safari5.1-6 *!*/
/*background: linear-gradient(to right, #4179F9 0%, #05B0DA 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
/*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4179F9', endColorstr='#1E243A', GradientType=0); !* IE6-9 *!*/
/*}*/

.ui.negative.button {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */ /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #EE3026 0%, #FB521E 100%); /* Chrome10-25,Safari5.1-6 */
    background: -webkit-linear-gradient(bottom left, #EE3026 0%, #FB521E 100%);
    background: linear-gradient(to top right, #EE3026 0%, #FB521E 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EE3026', endColorstr='#fb703c', GradientType=0); /* IE6-9 */
}

.ui.negative.button.warn {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */ /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, #ff8e1e 0%, #FFAB40 100%); /* Chrome10-25,Safari5.1-6 */
    background: -webkit-linear-gradient(bottom left, #ff8e1e 0%, #FFAB40 100%);
    background: linear-gradient(to top right, #ff8e1e 0%, #FFAB40 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFAB40', endColorstr='#FFAB40', GradientType=0); /* IE6-9 */
}

.ui.toggle.checkbox.label {
    color: white;
}

.ui.celled.inverted.selectable.table {
    background: #191F28;
    background: var(--primaryForeGround)
}

.ui.table td.positive, .ui.table tr.positive {
    background: #0E131A !important;
    background: var(--primaryBackground) !important;
    color: #9a9a9a !important;
}
.ui.table td.negative, .ui.table tr.negative {
    color: #dbdbdb !important;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */ /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #761e18 0%, #82200a 100%) !important; /* Chrome10-25,Safari5.1-6 */
    background: -webkit-linear-gradient(left, #0E131A -30%, #350909 70%, #bb2e21 100%) !important;
    background: linear-gradient(to right, #0E131A -30%, #350909 70%, #bb2e21 100%) !important;
    background: -webkit-linear-gradient(left, var(--primaryBackground) -30%, #350909 70%, #bb2e21 100%) !important;
    background: linear-gradient(to right, var(--primaryBackground) -30%, #350909 70%, #bb2e21 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#761e18', endColorstr='#82200a', GradientType=0) !important; /* IE6-9 */
}

.ui.pagination.menu {
    border-color: #8a8688;
    /*border-color: #8a8688;*/

}

.ui.pagination.menu .item {
    background: #0E131A;
    background: var(--primaryBackground);
    color: white;
}

.ui.action.input > input, .ui.action.input > div, .ui.active.visible.compact.selection.dropdown .text{
    background: #0E131A;
    background: var(--primaryBackground);
    color: white;
}

.gradient-red {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */ /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #EE3026 0%, #fb703c 100%); /* Chrome10-25,Safari5.1-6 */
    background: -webkit-linear-gradient(bottom left, #EE3026 0%, #fb703c 100%);
    background: linear-gradient(to top right, #EE3026 0%, #fb703c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EE3026', endColorstr='#fb703c', GradientType=0); /* IE6-9 */
}

.light-blue-gradient {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544 !important; /* Old browsers */ /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #4179F9 0%, #05B0DA 100%) !important; /* Chrome10-25,Safari5.1-6 */
    background: -webkit-linear-gradient(bottom left, #4179F9 0%, #05B0DA 100%) !important;
    background: linear-gradient(to top right, #4179F9 0%, #05B0DA 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4179F9', endColorstr='#1E243A', GradientType=0) !important; /* IE6-9 */
    border-radius: 4px;
}

.gradient-blue {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */ /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #394364 0%, #1E243A 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #394364 0%, #1E243A 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#394364', endColorstr='#1E243A', GradientType=0); /* IE6-9 */
}

.page-content {
    min-height: calc(100vh - 120px);
}

.footer {
    height: 50px;
    color: white
}

.App {
    text-align: center;
    min-height: calc(100vh - 70px);
}

.home {
    color: white
}

.chart {
    color: black;
    background-color: white;
    padding: 2em;
}

.home > div {
    min-height: 95%
}

.center-div {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 100px;
}

.button-array {
    max-width: 800px;
    margin: auto;
}

.button-array > button {
    width: 45%;
}

.vertical-center-content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.white {
    color: white;
}

.max-width-800 {
    max-width: 800px;
    margin: auto;
}

.max-height {
    height: 100%;
    min-height: 800px
}

.drop-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.steps {
    max-width: 800px;
    margin: auto;
}







